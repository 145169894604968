import React from 'react';
import { Box, Progress, Text, useBreakpointValue } from '@chakra-ui/react';

const ProgressBar = ({ progress, description }) => {
   const progressWidth = useBreakpointValue({
     base: "80%",
     md: "400px",
     lg: "500px",
   });
   const fontSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });
   const progressHeight = useBreakpointValue({
     base: "6px",
     md: "8px",
     lg: "10px",
   });
   
  console.log('Progress:', progress);
  console.log('Description:', description);

  return (
    <Box width={progressWidth} textAlign="center" mt={6} mx="auto">
      <Text fontSize={fontSize} mb={2} color="black">
        Beta Completion: {progress}%
      </Text>
      <Text fontSize={fontSize} mb={2} color="black">
        {description}
      </Text>
      <Progress
        borderRadius="999px"
        value={progress}
        color="purple"
        hasStripe
        isAnimated
        height={progressHeight}
      />
    </Box>
  );
};



export default ProgressBar;
